import {
  Card,
  CardWrapper,
  createSpanWithId,
  DataGrid,
  FormFirstFocus,
  PlatformFoutenSamenvatting
} from "adviesbox-shared";
import { Form, FormikProps } from "formik";
import React, { ReactElement, useContext, useState } from "react";
import { Column } from "react-table-6";
import AutorisatieNiveauContext from "../autorisatie-niveau-context/autorisatie-niveau-context";
import { StandaardtekstVoorOptions } from "../.generated/instellingen-forms/instellingen-formstypes";
import { InstellingNiveauOptions } from "../.generated/instellingen-forms/instellingen-formstypes";
import { SaveButton } from "../save-button/save-button";
import { Debug } from "../shared/components/formik/Debug";
import { WithSaveData } from "../shared/utils/save-data";
import withAdviesboxFormik from "../shared/utils/with-adviesbox-formik";
import { standaardTekstenSchema } from "./infra/standaard-teksten-schema";
import { StandaardTekstenProps, StandaardTekstenState, StandaardTekstType } from "./infra/standaard-teksten-types";
import { StandaardteksenOnderwerpen } from "./standaard-teksten-ajax";
import { StandaardTekstDetails } from "./standaard-teksten-details";

const newStandaardTextFactory = (): StandaardTekstType => {
  return { korteOmschrijving: "", standaardTekst: "", tekstVoor: StandaardtekstVoorOptions.OnsAdvies };
};

const StandaardTekstenComponent = (props: FormikProps<StandaardTekstenState> & StandaardTekstenProps): ReactElement => {
  const selectedState = useState(0);
  const [selected] = selectedState;
  const { isBeheerder } = useContext(AutorisatieNiveauContext);
  const { autorisatieNiveau } = useContext(AutorisatieNiveauContext);

  const getVoorTekst = (voor: StandaardtekstVoorOptions): string => {
    switch (voor) {
      case StandaardtekstVoorOptions.AfwijkendeKeuze:
        return "Afwijkende keuze";
      case StandaardtekstVoorOptions.OnsAdvies:
        return "Ons advies";
    }
  };

  const standaardTekstColumns: Column[] = [
    {
      Header: "Standaardtekst voor",
      id: "Standaardtekst voor",
      Cell: (c): ReactElement => createSpanWithId(c.index, 1, getVoorTekst(c.original.tekstVoor), c.original.tekstVoor)
    },
    {
      Header: "Korte omschrijving",
      id: "Korte omschrijving",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 1, c.original.korteOmschrijving, c.original.korteOmschrijving)
    },
    {
      Cell: "DeleteButton",
      maxWidth: 30
    }
  ];

  return (
    <FormFirstFocus>
      <Form className={"px-3"}>
        <PlatformFoutenSamenvatting />

        <fieldset
          disabled={!(isBeheerder || autorisatieNiveau.standaardTekstenNiveau === InstellingNiveauOptions.Medewerker)}
        >
          <CardWrapper className="px-3">
            <div className="text-container">
              <h2>Standaard teksten {mapOnderwerpToTitle(props.onderwerp)}</h2>
              <div className="save-btn-position">
                <SaveButton context={props} />
              </div>
            </div>
          </CardWrapper>

          <CardWrapper>
            <Card className="w-xl-100 w-lg-100 w-md-50 w-50">
              <DataGrid
                masterDetail
                rowCaption="Standaard tekst"
                columns={standaardTekstColumns}
                rowSelected={selectedState}
                name="teksten"
                showButtonDelete
                showButtonAddRow
                getNewRowValues={() => newStandaardTextFactory()}
              />
            </Card>
            {props.values.teksten[selected] && (
              <Card title="">
                <StandaardTekstDetails selected={selected} />
              </Card>
            )}
          </CardWrapper>
        </fieldset>
        <Debug />
      </Form>
    </FormFirstFocus>
  );
};

export const StandaardTeksten = withAdviesboxFormik<
  StandaardTekstenProps & WithSaveData<StandaardTekstenState>,
  StandaardTekstenState
>({
  // Transform outer props into form values
  mapPropsToValues: (e: StandaardTekstenProps): StandaardTekstenState => e,
  validationSchema: standaardTekstenSchema
})(StandaardTekstenComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") StandaardTeksten.displayName = "StandaardTeksten";

export function mapOnderwerpToTitle(onderwerp: StandaardteksenOnderwerpen): string {
  switch (onderwerp) {
    case StandaardteksenOnderwerpen["dubbelewoonlasten"]:
      return "dubbele woonlasten";
    case StandaardteksenOnderwerpen["energie"]:
      return "verduurzaming";
    case StandaardteksenOnderwerpen["inbrengeigengeld"]:
      return "eigen geld";
    case StandaardteksenOnderwerpen["wijzeopbouwdoelvermogen"]:
      return "opbouw doelvermogen";
    case StandaardteksenOnderwerpen["verantwoordewoonlasten"]:
      return "verantwoorde woonlasten";
    case StandaardteksenOnderwerpen["fiscaleaftrek"]:
      return "fiscaal";
    case StandaardteksenOnderwerpen["hypotheekaflossen"]:
      return "hypotheekvorm";
    case StandaardteksenOnderwerpen["afdekkenoverlijdensrisico"]:
      return "overlijden";
    case StandaardteksenOnderwerpen["afdekkenaoww"]:
      return "arbeidsongeschiktheid";
    case StandaardteksenOnderwerpen["afdekkenww"]:
      return "werkloosheid";
    case StandaardteksenOnderwerpen["pensioenvoorziening"]:
      return "pensioen";

    default:
      return onderwerp.toString();
  }
}
